import { Layout, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { HomeOutlined, TeamOutlined, MailOutlined } from '@ant-design/icons'
import { useAuthAdmin } from '../../../hooks/useAuth'
const { Header, Footer, Sider, Content } = Layout

export default function AdminLayout(props) {
    const [selectedKey, setSelectedKey] = useState<string>('home')
    useAuthAdmin()

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const adminRoute =
                window.location.pathname && window.location.pathname.split('/').length >= 3
                    ? window.location.pathname.split('/')[2]
                    : null
            if (!adminRoute) return
            setSelectedKey(adminRoute)
        }
    }, [props])

    return (
        <Layout>
            <Header></Header>
            <Layout>
                <Sider className="side-bar-reporting">
                    <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
                        <Menu.Item
                            key="home"
                            icon={<HomeOutlined />}
                            onClick={() => {
                                selectedKey !== 'home' && navigate('/ll-admin/')
                            }}
                        >
                            Home
                        </Menu.Item>
                        <Menu.Item
                            key="ll-users"
                            icon={<TeamOutlined />}
                            onClick={() => {
                                selectedKey !== 'll-users' && navigate('/ll-admin/ll-users/')
                            }}
                        >
                            LL §33A Users
                        </Menu.Item>
                        <Menu.Item
                            key="auto-emails"
                            icon={<MailOutlined />}
                            onClick={() => {
                                selectedKey !== 'auto-emails' && navigate('/ll-admin/auto-emails/')
                            }}
                        >
                            Auto E-mails
                        </Menu.Item>
                        <Menu.Item
                            key="compose-mail"
                            icon={<MailOutlined />}
                            onClick={() => {
                                selectedKey !== 'compose-mail' &&
                                    navigate('/ll-admin/compose-mail/')
                            }}
                        >
                            Compose Mail
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout>
                    <Content style={{ margin: 20 }}>{props.children}</Content>
                    <Footer>Footer</Footer>
                </Layout>
            </Layout>
        </Layout>
    )
}
