import UserPage from '../../../components/Admin/LLUser'
import React from 'react'
import Pagewrapper from '../../../components/PageWrapper'

export default function name(props) {
    return (
        <Pagewrapper>
            <UserPage userID={props.params.name} />
        </Pagewrapper>
    )
}