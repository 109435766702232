import React, { useEffect, useState } from 'react'
import AdminLayout from './AdminLayout'
import { httpGetAdmin, httpPostAdmin, httpPutAdmin } from '../../services/http'
import {
    Breadcrumb,
    Button,
    Descriptions,
    Divider,
    message,
    Popconfirm,
    Select,
    Tag,
    Timeline,
} from 'antd'
import { storeAuthToken } from '../../utils/auth-token'
import { add, isAfter, toDate } from 'date-fns'
import { formatText } from '../../lib/dateFunctions'

const { Option } = Select

const availableFeatureFlags = [
    'roster',
    'rule183',
    'ocr_doc',
    'client_side_reporting',
    'bank_crawl',
    'date_spec',
]
const availableAutoEmails = ['blanke_dage']

type usersData = {
    user_meta: any
    email_history: any[]
}

export function FeatureFlags({ userID }) {
    const [loading, setLoading] = useState<boolean>(false)
    const [userFeatureFlags, setUserFeatureFlags] = useState<string[]>([])

    async function updateFeatureFlags(values) {
        if (!userID) return
        setLoading(true)
        await httpPutAdmin(`/ll-admin/ll-users/${userID}/feature-flags`, {
            feature_flags: values,
        })
        const res = await httpGetAdmin<usersData>(`/ll-admin/ll-users/${userID}`)
        setUserFeatureFlags(res.data.user_meta.feature_flags?.split(' '))
        setLoading(false)
    }

    useEffect(() => {
        httpGetAdmin<usersData>(`/ll-admin/ll-users/${userID}`).then((res) => {
            setUserFeatureFlags(res.data.user_meta.feature_flags?.split(' '))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID])

    return (
        <Select
            loading={loading}
            mode="multiple"
            style={{ width: 200 }}
            placeholder="No flags"
            value={userFeatureFlags}
            onChange={updateFeatureFlags}
            maxTagCount="responsive"
        >
            {availableFeatureFlags.map((ff) => {
                return (
                    <Option key={ff} value={ff}>
                        {ff}
                    </Option>
                )
            })}
        </Select>
    )
}

export function AutoEmails({ userID }) {
    const [loading, setLoading] = useState<boolean>(false)
    const [userAuto_emails, setUserAuto_emails] = useState<string[]>([])

    async function updateAutoEmails(values) {
        if (!userID) return
        setLoading(true)
        await httpPutAdmin(`/ll-admin/ll-users/${userID}/auto-emails`, {
            auto_emails: values,
        })
        const res = await httpGetAdmin<usersData>(`/ll-admin/ll-users/${userID}`)
        setUserAuto_emails(res.data.user_meta.auto_emails?.split(' '))
        setLoading(false)
    }

    useEffect(() => {
        httpGetAdmin<usersData>(`/ll-admin/ll-users/${userID}`).then((res) => {
            setUserAuto_emails(res.data.user_meta.auto_emails?.split(' '))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID])

    return (
        <Select
            loading={loading}
            mode="multiple"
            style={{ width: 200 }}
            placeholder="No emails"
            value={userAuto_emails}
            onChange={updateAutoEmails}
            maxTagCount="responsive"
        >
            {availableAutoEmails.map((ff) => {
                return (
                    <Option key={ff} value={ff}>
                        {ff}
                    </Option>
                )
            })}
        </Select>
    )
}

export default function AdminLLUser({ userID }) {
    const [userData, setUserData] = useState<any>(null)
    const [isVerifying, setIsVerifying] = useState<boolean>(false)
    const [disableSendMail, setDisableSendMail] = useState<boolean>(false)
    const [newsletterJustSent, setNewsletterJustSent] = useState<boolean>(false)
    const [impersonateLoading, setImpersonateLoading] = useState<boolean>(false)
    const [emailHistory, setEmailHistory] = useState<any[]>([])

    async function getUserData() {
        if (!userID) return
        const res = await httpGetAdmin<usersData>(`/ll-admin/ll-users/${userID}`)
        setUserData(res.data.user_meta)
        setEmailHistory(res.data.email_history)
    }

    useEffect(() => {
        getUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID])

    async function confirm() {
        if (!userID) return
        setIsVerifying(true)
        await httpPostAdmin(`/ll-admin/ll-users/${userID}/verify-email`)
        await getUserData()
        setIsVerifying(false)
    }

    async function sendVerifyEmail() {
        if (!userID) return
        setIsVerifying(true)
        await httpPostAdmin(`/ll-admin/ll-users/${userID}/re-send-verify-email`)
        await getUserData()
        setIsVerifying(false)
        setDisableSendMail(true)
    }

    async function sendNewsletterBilag(test = true) {
        if (!userID) return
        try {
            await httpPostAdmin(`/ll-admin/ll-users/${userID}/send-newsletter-blanke-dage`, {
                is_test_mail: test,
            })
            await getUserData()
        } catch (ex) {
            message.error(`Something went wrong: ${ex.data?.detail}`)
        }
        setNewsletterJustSent(true)
    }

    async function sendSACTrialExpiresSoon(test = true) {
        if (!userID) return
        try {
            await httpPostAdmin(`/ll-admin/ll-users/${userID}/send-sac-trial-expire-mail`, {
                is_test_mail: test,
            })
            await getUserData()
        } catch (ex) {
            message.error(`Something went wrong: ${ex.data?.detail}`, 5)
        }
        setNewsletterJustSent(true)
    }

    function userflowEmailWasSent() {
        const email = emailHistory.find((e) => e.email_type === 'userflow_onboarding_notice')
        return email
    }

    function sacTrialExpireMayBeSent() {
        const emailWasNotSent = !emailHistory.find(
            (e) => e.email_type === 'sac_free_trial_expire_soon_email'
        )
        return (
            emailWasNotSent &&
            userData?.has_paid !== true &&
            userData?.current_period_end &&
            isAfter(add(new Date(userData?.current_period_end), { days: 10 }), new Date())
        )
    }

    async function sendUserflowEmail(test = true) {
        if (!userID) return
        const email = userflowEmailWasSent()
        if (email) {
            message.error(
                `Userflow email has already been sent to the user: ${email.created_on}`,
                3
            )
        }
        try {
            await httpPostAdmin(
                `/ll-admin/ll-users/${userID}/send-userflow-onboarding-notice-email`,
                {
                    is_test_mail: test,
                }
            )
            await getUserData()
        } catch (ex) {
            message.error(`Something went wrong: ${ex.data?.detail}`, 5)
        }
        setNewsletterJustSent(true)
    }

    async function impersonateLLUser() {
        if (!userID) return
        setImpersonateLoading(true)
        type impersonateRes = {
            access_token: string
        }
        const res = await httpGetAdmin<impersonateRes>(`/ll-admin/ll-users/${userID}/impersonate`)
        storeAuthToken(res.data?.access_token || '')
        let redirectingTo = '/app/'
        if (typeof window !== 'undefined') {
            window.open(redirectingTo, '_blank')
            setImpersonateLoading(false)
        }
    }

    function rennderSendMail(sendMailFun, mailTitle, isPrimary = false) {
        return (
            <div style={{ display: 'inline' }}>
                <Popconfirm
                    title="Are you sure to send this newsletter?"
                    onConfirm={() => {
                        sendMailFun(false)
                    }}
                    disabled={newsletterJustSent}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        ghost
                        danger
                        type={(isPrimary && 'primary') || 'default'}
                        disabled={newsletterJustSent}
                    >
                        Send "{mailTitle}" to {userData?.name}
                    </Button>
                </Popconfirm>{' '}
                <Button
                    onClick={() => {
                        sendMailFun()
                    }}
                    type={(isPrimary && 'primary') || 'default'}
                    disabled={newsletterJustSent}
                >
                    Send "{mailTitle}" to yourself
                </Button>
            </div>
        )
    }

    return (
        <AdminLayout>
            <div style={{ marginBottom: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href="/ll-admin/ll-users">ll-users</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{userID ? userID : 'll-user'}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Descriptions title="User Info" bordered>
                <Descriptions.Item label="Name">{userData?.name}</Descriptions.Item>
                <Descriptions.Item label="Email">{userData?.email}</Descriptions.Item>
                <Descriptions.Item label="Subscription reset">
                    {userData?.current_period_end}
                </Descriptions.Item>
                <Descriptions.Item label="Has paid">
                    {userData?.has_paid !== null && userData?.has_paid !== undefined ? (
                        userData?.has_paid ? (
                            <Tag color="success">Yes</Tag>
                        ) : (
                            <Tag color="error">No</Tag>
                        )
                    ) : (
                        'No data'
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Email verified">
                    {userData?.email_verified !== null && userData?.email_verified !== undefined ? (
                        userData?.email_verified ? (
                            <Tag color="success">Yes</Tag>
                        ) : (
                            <>
                                <Tag color="error">No</Tag>
                                <Popconfirm
                                    title="Are you sure to verify email?"
                                    onConfirm={confirm}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button disabled={disableSendMail} loading={isVerifying}>
                                        Verify
                                    </Button>
                                </Popconfirm>{' '}
                                <Popconfirm
                                    title="Are you sure to re-send verify email?"
                                    onConfirm={sendVerifyEmail}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button disabled={disableSendMail} loading={isVerifying}>
                                        Re-Send verification mail
                                    </Button>
                                </Popconfirm>
                            </>
                        )
                    ) : (
                        'No data'
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Feature flags">
                    <FeatureFlags userID={userID} />
                </Descriptions.Item>
                <Descriptions.Item label="Auto emails">
                    <AutoEmails userID={userID} />
                </Descriptions.Item>
                <Descriptions.Item label="Subscribes to newsletters">
                    {userData?.has_unsubscribed_newsletter !== null &&
                    userData?.has_unsubscribed_newsletter !== undefined ? (
                        userData?.has_unsubscribed_newsletter ? (
                            <Tag color="error">No</Tag>
                        ) : (
                            <Tag color="success">Yes</Tag>
                        )
                    ) : (
                        'No data'
                    )}
                </Descriptions.Item>
            </Descriptions>
            <Divider />
            <div style={{ marginTop: 10 }}>
                <Popconfirm
                    title="REMEMBER TO LOG OUT AFTER USE!!!!"
                    onConfirm={impersonateLLUser}
                    okText="Ok"
                    cancelText="Cancel"
                >
                    <Button danger loading={impersonateLoading}>
                        Impersonate
                    </Button>
                </Popconfirm>
            </div>
            <Divider />
            <div
                style={{
                    marginTop: 10,
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    rowGap: '1em',
                }}
            >
                <h3>Newsletters{userData?.email && ` for ${userData?.email}`}</h3>
                {sacTrialExpireMayBeSent() &&
                    rennderSendMail(sendSACTrialExpiresSoon, 'SAC LC FREE TRIAL EXPIRES SOON')}
                {!userflowEmailWasSent() && rennderSendMail(sendUserflowEmail, 'Nye guides')}
                {rennderSendMail(sendNewsletterBilag, 'vær på forkant med dine bilag')}
            </div>
            <br />
            <div style={{ width: '50%', alignItems: 'center' }}>
                <h3 style={{ textAlign: 'center' }}>Email history:</h3>
                <Timeline mode="left">
                    {emailHistory.map((email) => {
                        return (
                            <Timeline.Item
                                label={formatText(
                                    toDate(new Date(email.created_on)),
                                    'yyyy-MM-dd HH:mm:ss'
                                )}
                            >
                                {email.email_type}
                            </Timeline.Item>
                        )
                    })}
                </Timeline>
            </div>
            <Divider />
        </AdminLayout>
    )
}
